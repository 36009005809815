<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="warning"
          icon="mdi-account-multiple"
          title="Total Anggota"
          :value="String(summary.data.user_total)"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="rgb(233, 30, 99)"
          icon="mdi-account"
          title="Anggota Baru"
          :value="String(summary.data.user_new)"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="success"
          icon="mdi-comment-plus"
          title="Komentar Terbaru"
          :value="String(summary.data.comment)"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-email-alert"
          title="Pesan Terbaru"
          :value="String(summary.data.unread)"
        />
      </v-col>
    </v-row>
    <!--  -->
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="6"
      >
        <base-material-card
          color="primary"
          class="px-6 py-3"
          title="Anggota"
        >
          <template>
            <div
              v-if="!isLoading"
              class="text-center mt-5 mb-5"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              />
            </div>
            <!--  -->
            <div v-else>
              <v-simple-table>
                <template v-slot:default>
                  <!--  -->
                  <thead>
                    <tr>
                      <th class="text-left primary--text">
                        #
                      </th>
                      <th class="text-center primary--text">
                        Nama
                      </th>
                      <th class="text-left primary--text">
                        Terdaftar Pada
                      </th>
                    </tr>
                  </thead>
                  <!--  -->
                  <tbody>
                    <!--  -->
                    <tr
                      v-for="(data, index) in anggota.data"
                      :key="data.id"
                    >
                      <td>{{ index + anggota.meta.from }}</td>
                      <td class="text-center">
                        {{ data.name }}
                      </td>
                      <td>{{ data.created_at | moment('D MMM YYYY') }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </template>
          <div>
            <template>
              <!--  -->
              <v-fab-transition>
                <v-btn
                  to="/anggota"
                  color="info"
                  fab
                  dark
                  small
                  absolute
                  bottom
                  right
                >
                  <v-icon>mdi-magnify-plus-outline</v-icon>
                </v-btn>
              </v-fab-transition>
            </template>
          </div>
        </base-material-card>
      </v-col>
      <!--  -->
      <v-col
        cols="12"
        md="6"
        lg="6"
      >
        <base-material-card
          color="primary"
          class="px-6 py-3"
          title="Komentar"
        >
          <template>
            <div
              v-if="!isLoading"
              class="text-center mt-5 mb-5"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              />
            </div>
            <!--  -->
            <div v-else>
              <v-simple-table>
                <template v-slot:default>
                  <!--  -->
                  <thead>
                    <tr>
                      <th class="text-left primary--text">
                        Komentar
                      </th>
                      <th class="text-left primary--text">
                        Nama Akun
                      </th>
                      <th class="text-right primary--text">
                        Judul Thread
                      </th>
                    </tr>
                  </thead>
                  <!--  -->
                  <tbody>
                    <!--  -->
                    <tr
                      v-for="data in komentar.data"
                      :key="data.id"
                    >
                      <td class="text-left">
                        {{ data.post.substr(0, 20)
                        }}{{ data.post.length > 20 ? '...' : '' }}
                      </td>
                      <td class="text-left">
                        {{ data.user.name.substr(0, 20)
                        }}{{ data.user.name.length > 20 ? '...' : '' }}
                      </td>
                      <td
                        class="text-right"
                        style="text-decoration: underline; cursor: pointer"
                        @click="showForum(data.thread.id)"
                      >
                        <!-- :to="'/forum/show/' + data.thread.id" -->
                        {{ data.thread.title.substr(0, 20)
                        }}{{ data.thread.title.length > 20 ? '...' : '' }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </template>
          <div>
            <template>
              <!--  -->
              <v-fab-transition>
                <v-btn
                  to="/forum"
                  color="info"
                  fab
                  dark
                  small
                  absolute
                  bottom
                  right
                >
                  <v-icon>mdi-magnify-plus-outline</v-icon>
                </v-btn>
              </v-fab-transition>
            </template>
          </div>
        </base-material-card>
      </v-col>
    </v-row>
    <!--  -->
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    name: 'DashboardDashboard',

    data () {
      return {
        isLoading: false,
        isLoadingComment: false,
        isLoadingSummary: false,
        anggota: {
          data: {
            name: '',
          },
          meta: {},
        },
        komentar: {
          meta: {},
          data: {},
        },
        summary: {
          meta: {},
          data: {
            user_total: 0,
            user_new: 0,
            comment: 0,
            unread: 0,
          },
        },
      }
    },
    mounted () {
      this.getAnggota()
      this.getKomentar()
      this.getSummary()
    },

    methods: {
      showForum (id) {
        return this.$router.push('/forum/' + id + '/show')
      },
      setKomentar (data) {
        this.komentar = data
      },
      getKomentar () {
        axios
          .get('/v1/forum/thread/comment?entities=user,thread&sort=-id')
          .then(response => {
            this.setKomentar(response.data)
            this.isLoadingComment = true
          })
      },
      setSummary (data) {
        this.summary = data
      },
      getSummary () {
        axios.get('/v1/user/dashboard/summary').then(response => {
          this.setSummary(response.data)
          this.isLoadingSummary = true
        })
      },
      setAnggota (data) {
        this.anggota = data
      },
      getAnggota () {
        axios
          .get('/v1/user?per_page=10&entities=region&sort=-id')
          .then(response => {
            this.setAnggota(response.data)
            this.isLoading = true
          })
          .catch(e => {})
      },
    },
  }
</script>
